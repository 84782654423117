<template>

  <el-dialog
      :title='taskName + "任务日志"'
      :close-on-click-modal="false"
      :visible.sync="visible"
      v-if="visible"
      @close="closeDialog"
      width="75%">

    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        style="width: 100%">
<!--      <el-table-column-->
<!--          prop="id"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          width="80"-->
<!--          label="ID">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="params"
          header-align="center"
          align="center"
          min-width="10%"
          :show-overflow-tooltip="true"
          label="任务ID">
      </el-table-column>
      <el-table-column
          prop="username"
          header-align="center"
          align="center"
          min-width="20%"
          label="用户名">
      </el-table-column>
      <el-table-column
          prop="operation"
          header-align="center"
          align="center"
          min-width="30%"
          label="用户操作">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="method"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          width="150"-->
<!--          :show-overflow-tooltip="true"-->
<!--          label="请求方法">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="params"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          width="150"-->
<!--          :show-overflow-tooltip="true"-->
<!--          label="请求参数">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="time"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          label="执行时长(毫秒)">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="ip"
          header-align="center"
          align="center"
          min-width="20%"
          label="IP地址">
      </el-table-column>
      <el-table-column
          prop="createDate"
          header-align="center"
          align="center"
          min-width="20%"
          label="时间">
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </el-dialog>
</template>

<script>
export default {
  name: "checkJobLog",
  data() {
    return {
      visible: false,
      dataList: [],
      key: 0,
      taskName: '',
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
    }
  },
  methods: {
    init (taskId, taskName) {
      this.visible = true
      this.key = taskId
      this.taskName = taskName
      this.getDataList()
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      // let paramsMap = new Map()
      // paramsMap.set('page', this.pageIndex)
      // paramsMap.set('limit', this.pageSize)
      // paramsMap.set('key', '')
      // this.getRequest("/sys/log/list", paramsMap).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.dataList = data.page.list
      //     this.totalPage = data.page.totalCount
      //   } else {
      //     this.dataList = []
      //     this.totalPage = 0
      //   }
      //   this.dataListLoading = false
      // })
      this.$http({
        url: this.$http.adornUrl('/sys/log/list'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'key': this.key
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    closeDialog() {
      this.visible = false;
      this.dataList = []
      this.totalPage = 0
    },
  }
}
</script>

<style scoped>

</style>